import React from "react"
import { useSelector } from "react-redux"
import {
  GENERAL_FILTER_FIELDS,
  GeneralFiltersItemFilterModelKeys,
} from "./filter-constants"
import { RootState } from "store"
import { FilterableMultiselect } from "components/filterable-multiselect/filterable-multiselect"
import { CatalogDynamicFiltersModel } from "common/models/CatalogDynamicFiltersModel"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import Tippy from "@tippyjs/react"
import { Icon, ICONS } from "components/icon/icon"

export const FilterLeftSide = ({
  isActive,
  label,
  columnCount,
  selectedOptions,
}: {
  isActive: boolean
  label: string
  columnCount: number
  selectedOptions: string[]
}) => {
  return (
    <div className="flex w-full justify-between items-center pr-6">
      <span
        className={`text-caption-1 ${
          isActive ? "text-gray-900 font-medium" : "text-gray-500"
        }`}
      >
        {label}
      </span>
      {isActive && (
        <Tippy
          duration={[50, 0]}
          content={
            <div
              className="gap-2 p-4 bg-white rounded-md"
              style={{
                boxShadow:
                  "0px 1px 4px rgba(0, 0, 0, 0.07), 0px 2px 8px rgba(0, 0, 0, 0.08)",
              }}
            >
              <div className="text-caption-1 font-medium text-gray-900 pb-1">
                Selected
              </div>
              <div
                className="grid gap-x-2 overflow-y-auto overflow-x-hidden max-h-[500px]"
                style={{
                  gridTemplateColumns: `repeat(${columnCount},  1fr)`,
                }}
              >
                {selectedOptions?.map((selectedOption) => (
                  <div className="bullets w-[150px]" key={selectedOption}>
                    <div className="w-1 h-1 bg-gray-500 rounded self-center mr-1" />
                    <div
                      className="text-caption-1 text-gray-500 self-center whitespace-nowrap overflow-hidden text-ellipsis"
                      title={selectedOption}
                    >
                      {selectedOption}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
          interactive
          appendTo={document.body}
          placement="left"
        >
          <span>
            <Icon
              icon={ICONS.INFORMATION_CIRCLE_OUTLINED}
              size={4.5}
              className="text-gray-500"
            />
          </span>
        </Tippy>
      )}
    </div>
  )
}

export const GeneralFilters = ({
  filterObject,
  setFilterObject,
}: {
  filterObject: ItemFilterModel
  setFilterObject: React.Dispatch<React.SetStateAction<ItemFilterModel>>
}) => {
  const auditCatalog = useSelector((state: RootState) => state.auditCatalog)
  const catalogFilterModel =
    auditCatalog.filterModel ?? ({} as CatalogDynamicFiltersModel)

  const {
    dataSource,
    jobNames,
    brands,
    categories,
    firstSubCategories,
    secondSubCategories,
    systemTags,
    customTags,
  } = catalogFilterModel

  const generalFiltersOptionsObject = {
    dataSource: dataSource ?? [],
    auditNames: jobNames?.map((el) => el.value ?? "") ?? [],
    fulfillmentType: ["1P", "2P", "3P"],
    brands: brands?.map((el) => el.value ?? "") ?? [],
    mainCategory: categories?.map((el) => el.value ?? "") ?? [],
    subCategory1: firstSubCategories?.map((el) => el.value ?? "") ?? [],
    subCategory2: secondSubCategories?.map((el) => el.value ?? "") ?? [],
    systemTags: systemTags ?? [],
    customTags: customTags ?? [],
  }

  const setFilterOptions =
    (accessor: GeneralFiltersItemFilterModelKeys) => (optionList: string[]) => {
      const newFilterObject = { ...filterObject }
      newFilterObject[accessor] = optionList
      setFilterObject(newFilterObject)
    }

  return (
    <div className="general-filters-content">
      {GENERAL_FILTER_FIELDS.map((field, index) => {
        const { label, accessor, filterObjectAccessor } = field

        const options = generalFiltersOptionsObject[accessor] ?? []
        const hasCommonAccessor = filterObjectAccessor === "tags"
        const selectedOptions = filterObject[filterObjectAccessor] ?? []
        const filteredSelectedOptions = selectedOptions.filter((option) =>
          options.includes(option),
        )
        const isActive = filteredSelectedOptions.length !== 0
        const columnCount = filteredSelectedOptions.length > 19 ? 2 : 1

        return (
          <div
            key={`${label}-${index}`}
            className={`flex justify-between px-3.5 py-[3px] ${
              index % 2 === 0 ? "rounded bg-gray-50" : ""
            }`}
          >
            <FilterLeftSide
              isActive={isActive}
              label={label}
              columnCount={columnCount}
              selectedOptions={filteredSelectedOptions}
            />
            <FilterableMultiselect
              options={options}
              selectedOptions={selectedOptions}
              setSelectedOptions={setFilterOptions(filterObjectAccessor)}
              hasCommonAccessor={hasCommonAccessor}
            />
          </div>
        )
      })}
    </div>
  )
}
