import { useSelector } from "react-redux"
import { RootState } from "store"
import { FilterLeftSide } from "./general-filters"
import { FilterableMultiselect } from "components/filterable-multiselect/filterable-multiselect"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import { Icon, ICONS } from "components/icon/icon"

export const CustomFieldsFilters = ({
  filterObject,
  setFilterObject,
}: {
  filterObject: ItemFilterModel
  setFilterObject: React.Dispatch<React.SetStateAction<ItemFilterModel>>
}) => {
  const auditCatalog = useSelector((state: RootState) => state.auditCatalog)
  const customFilters = auditCatalog?.filterModel?.customFields ?? []
  const currentCustomFilters = filterObject?.customFields ?? []

  const areCustomFiltersEmpty =
    customFilters.length === 0 ||
    customFilters.every((filter) => filter.values[0] === null)

  const setFilterOptions = (key: string) => (optionList: string[]) => {
    let newCustomFilters = filterObject?.customFields?.map((el) => el) ?? []
    const filterIndex = newCustomFilters.findIndex((filter) => filter.key === key)

    if (filterIndex > -1) {
      if (optionList.length > 0) {
        newCustomFilters[filterIndex] = { key, values: optionList }
      } else {
        newCustomFilters = newCustomFilters.filter((filter) => filter.key !== key)
      }
    } else {
      newCustomFilters.push({ key, values: optionList })
    }

    setFilterObject({ ...filterObject, customFields: newCustomFilters })
  }

  return (
    <div className="general-filters-content h-full">
      {areCustomFiltersEmpty ? (
        <div className="flex flex-col justify-center">
          <div className="flex gap-1 items-center self-center justify-center">
            <Icon icon={ICONS.BELL} />
            <span>This workspace has no custom fields.</span>
          </div>
        </div>
      ) : (
        customFilters.map((customFilter, index) => {
          const { key = "", values = [] } = customFilter
          if (values[0] === null) return

          const selectedOptions =
            currentCustomFilters.find((el) => el.key === key)?.values ?? []
          const isActive = selectedOptions.length !== 0
          const columnCount = selectedOptions.length > 19 ? 2 : 1

          return (
            <div
              key={`${key}-${index}`}
              className={`flex justify-between px-3.5 py-[3px] ${
                index % 2 === 0 ? "rounded bg-gray-50" : ""
              }`}
            >
              <FilterLeftSide
                isActive={isActive}
                label={key}
                columnCount={columnCount}
                selectedOptions={selectedOptions}
              />
              <FilterableMultiselect
                options={values}
                selectedOptions={selectedOptions}
                setSelectedOptions={setFilterOptions(key)}
              />
            </div>
          )
        })
      )}
    </div>
  )
}
