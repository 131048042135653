import { FC, useEffect, useState } from "react"
import { scoreToColor, scoreToStatus } from "utils"
import { green, red, yellow } from "utils/colors"

type CardScoreProps = {
  title: string
  status?: string
  score: number
  className?: string
}

export const getBgColor = (score: number) => {
  if (score >= 0 && score < 40) return red[50]
  if (score >= 40 && score < 80) return yellow[50]
  if (score >= 80 && score <= 100) return green[50]
  return ""
}

export const CardScore: FC<CardScoreProps> = ({
  title,
  score,
  className,
}: CardScoreProps) => {
  const [sectionScore, setSectionScore] = useState<number>()
  const [sectionStatus, setSectionStatus] = useState<string>()

    useEffect(() => {
    if (score === null) {
      setSectionStatus("--")
      setSectionScore(score)
    } else if (score === undefined) {
      setSectionStatus(scoreToStatus(score))
      setSectionScore(score)
    } else {
      if (score === 0) {
        if (title === "Rating Score") {
          setSectionStatus("No Ratings")
          setSectionScore(0)
        } else if (title === "Review Score") {
          setSectionStatus("No Reviews")
          setSectionScore(0)
        } else {
          setSectionStatus("Needs Improvement")
          setSectionScore(0)
        }
      } else if (score > 0) {
        setSectionStatus(scoreToStatus(score))
        setSectionScore(score)
      }
    }
  }, [score, title])

  return (
    <div className={`score-container ${className}`}>
      <div className="score-card">
        <div
          className={`score-value`}
          style={{
            color: scoreToColor(sectionScore),
            background: `linear-gradient(119deg, ${getBgColor(
              sectionScore ?? 0,
            )} 0%, rgba(255, 255, 255, 0) 82.36%)`,
          }}
        >
          {sectionScore !== undefined && sectionScore !== null ? sectionScore : "--"}
        </div>
        <div className="score-status">{sectionStatus}</div>
      </div>
    </div>
  )
}

type InfoElementProps = {
  title: string
  value?: string | number | undefined
  children?: any
}

export const InfoElement: FC<InfoElementProps> = ({
  title,
  value,
  children,
}: InfoElementProps) => {
  return (
    <div className="info-element">
      <span className="title">{title || "--"}</span>
      {value ? (
        <span className="value">
          {typeof value == "number" ? value.toFixed(2) : value || "--"}
        </span>
      ) : (
        children
      )}
    </div>
  )
}

type SpecItemProps = {
  title: string | undefined
  value: string | undefined
  className?: string
}

export const SpecItem: FC<SpecItemProps> = ({
  title,
  value,
  className,
}: SpecItemProps) => {
  return (
    <div className={`spec-item ${className || ""}`}>
      <span className="spec-title">{title}</span>
      <span className="spec-value">{value}</span>
    </div>
  )
}
