import { Controller, useFormContext } from "react-hook-form"
import "./select.scss"
import { BaseFormComponent } from "../base-form-component/base-form-component"
import { InnerSelect } from "./inner-select/inner-select"

export type SelectOption = {
  value: any
  label: string | React.ReactNode
  disabled?: boolean
}

export type SelectProps = {
  options: SelectOption[]
  optionRender?: (option: SelectOption) => JSX.Element
  valueRender?: (option: SelectOption) => JSX.Element
  onChange?: (option: any, action?: any) => void
  onBlur?: () => void
  onFocus?: () => void
  placeholder?: string
  label?: string
  styles?: any
  hideIcon?: boolean
  showIconDivider?: boolean
  name?: string
  value?: any | any[]
  className?: string
  disabled?: boolean
  required?: any
  isMulti?: boolean
  isCreatable?: boolean
  readOnly?: boolean
  hideSelectedValues?: boolean
  errors?: any[]
  menuPlacement?: any
  isClearable?: boolean
  isSearchable?: boolean
  controlHeight?: number | string
  labelClassName?: string
}

export const Select: React.FC<SelectProps> = ({
  options = [],
  optionRender,
  valueRender,
  onChange,
  onBlur,
  onFocus,
  placeholder = "Select",
  label,
  name,
  hideIcon = false,
  showIconDivider = false,
  className = "",
  disabled,
  required,
  readOnly = false,
  value = null,
  isMulti = false,
  isCreatable = false,
  hideSelectedValues = false,
  styles,
  menuPlacement = "bottom",
  isClearable,
  isSearchable = false,
  controlHeight = 48,
  labelClassName = "",
}) => {
  const { setValue, control } = useFormContext() || {}

  const handleSelect = (option: any) => {
    setValue(name || "", isMulti ? option : option.value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  return name && control ? (
    <BaseFormComponent
      name={name}
      label={label}
      required={required}
      className={className}
      noErrorMessage
    >
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field }) => {
          return (
            <InnerSelect
              options={options}
              name={name}
              hideIcon={hideIcon}
              showIconDivider={showIconDivider}
              value={field.value}
              onChange={handleSelect}
              onBlur={field.onBlur}
              onFocus={onFocus}
              readOnly={readOnly}
              disabled={disabled}
              isMulti={isMulti}
              isCreatable={isCreatable}
              placeholder={placeholder}
              optionRender={optionRender}
              valueRender={valueRender}
              hideSelectedValues={hideSelectedValues}
              styles={styles}
              menuPlacement={menuPlacement}
              isClearable={isClearable}
              isSearchable={isSearchable}
              controlHeight={controlHeight}
            />
          )
        }}
      />
    </BaseFormComponent>
  ) : (
    <div className={className}>
      {Boolean(label) === true && (
        <div
          className={`block mb-2 text-caption-1 pl-1 text-gray-${
            disabled ? "300" : "900"
          } font-medium ${labelClassName}`}
        >
          {label}
        </div>
      )}
      <InnerSelect
        options={options}
        name={name}
        hideIcon={hideIcon}
        showIconDivider={showIconDivider}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        readOnly={readOnly}
        disabled={disabled}
        isMulti={isMulti}
        isCreatable={isCreatable}
        placeholder={placeholder}
        optionRender={optionRender}
        valueRender={valueRender}
        hideSelectedValues={hideSelectedValues}
        styles={styles}
        menuPlacement={menuPlacement}
        isClearable={isClearable}
        isSearchable={isSearchable}
        controlHeight={controlHeight}
      />
    </div>
  )
}
