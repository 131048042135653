import { FC, useState } from "react"
import { Icon, ICONS } from "components/icon/icon"

type Props = {
  placeholder: string
  onChange: (e: any) => void
  className?: string
  disabled?: boolean
  value?: string
  defaultValue?: string
  height?: number
}

export const SearchInput: FC<Props> = ({
  placeholder,
  onChange,
  className,
  disabled,
  value,
  defaultValue,
  height,
}) => {
  const [inputValue, setInputValue] = useState(value || defaultValue || "")

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    onChange(e)
  }

  const handleClearInput = () => {
    setInputValue("")
    onChange({ target: { value: "" } })
  }

  return (
    <div className={`relative ${className}`} style={{ height: height }}>
      <input
        placeholder={placeholder}
        className={`border border-gray-300 text-body px-2 h-10 rounded-md placeholder-gray-300 pr-8 w-full${
          !disabled
            ? " hover:border-gray-400 focus:outline-none focus:border-blue-600 focus:border-2 focus:px-[7px]"
            : " bg-gray-50"
        }`}
        onChange={handleInputChange}
        disabled={disabled}
        value={inputValue}
        defaultValue={defaultValue}
        style={{ height: height }}
      />
      <div
        className={`absolute inset-y-0 right-4 flex items-center top-0.5 cursor-pointer ${
          disabled ? " text-gray-300" : ""
        }`}
        onClick={inputValue && !disabled ? handleClearInput : undefined}
      >
        <Icon icon={inputValue ? ICONS.CANCEL : ICONS.SEARCH} size={4} />
      </div>
    </div>
  )
}
