import { useEffect, useRef, useState } from "react"
import "../filterable-multiselect/filterable-multiselect.scss"
import { Icon, ICONS } from "components/icon/icon"
import { createPortal } from "react-dom"

export const FilterSelect = ({
  options,
  value,
  setSelectedOption,
  isDisabled = false,
  width = 230,
  menuHeight = 214,
  controlHeight = 24,
}: {
  options: { label: string; value: string | boolean }[]
  value: string | boolean | undefined
  setSelectedOption: (
    option: {
      label: string
      value: string | boolean
    } | null,
  ) => void
  isDisabled?: boolean
  width?: number
  menuHeight?: number
  controlHeight?: number
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const isAnyOptionSelected = value !== undefined

  const selectedOption = options.find((option) => option.value === value) || null

  const handleOptionClick = (
    option: {
      label: string
      value: string | boolean
    } | null,
  ) => {
    setSelectedOption(option)
    setIsMenuOpen(false)
  }

  const clearSelection = () => {
    setSelectedOption(null)
  }

  const handleInputClick = () => {
    if (!isDisabled) {
      setIsMenuOpen(true)
      if (inputRef) inputRef.current?.focus()
    }
  }

  const dropdown = (
    <div
      ref={dropdownRef}
      className="absolute z-20 mt-1 bg-white border rounded shadow overflow-y-auto py-1.5 pl-3 pr-1.5"
      style={{
        top: inputRef.current?.getBoundingClientRect().bottom ?? 0 + window.scrollY,
        right: window.innerWidth - (inputRef.current?.getBoundingClientRect().right ?? 0),
        width,
        maxHeight: menuHeight,
      }}
    >
      {options.map((option, index) => {
        return (
          <label
            key={`${option}-${index}`}
            className={`flex items-center gap-3 h-[30px] ${
              option.value === selectedOption?.value && "bg-gray-100"
            } pl-1 hover:bg-gray-100 rounded`}
            onClick={() => handleOptionClick(option)}
          >
            <div
              className="overflow-hidden whitespace-nowrap text-ellipsis text-caption-2 font-semibold"
              title={option?.label}
            >
              {option?.label}
            </div>
          </label>
        )
      })}
    </div>
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className="relative">
      <input
        ref={inputRef}
        placeholder={isAnyOptionSelected ? "" : "Select"}
        style={{ width: width, height: controlHeight }}
        className={`w-full h-full p-2 border rounded border-gray-300 focus:border-blue-600 focus:outline-none placeholder:text-gray-300 ${
          isDisabled ? "opacity-50" : ""
        } text-caption-2 font-semibold w-[150px] cursor-default`}
        value={selectedOption ? selectedOption.label : ""}
        onClick={handleInputClick}
        readOnly
      />
      {isAnyOptionSelected && (
        <div
          onClick={clearSelection}
          className="absolute right-6 top-[5px] border-r border-gray-200 pr-1"
        >
          <Icon icon={ICONS.CANCEL} size={3.5} className="" />
        </div>
      )}
      <div onClick={handleInputClick}>
        <Icon
          icon={isMenuOpen ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
          size={3.5}
          className="absolute right-2 top-[5px] transition-transform duration-200"
        />
      </div>

      {isMenuOpen && createPortal(dropdown, document.body)}
    </div>
  )
}
