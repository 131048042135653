import { RematchDispatch } from "@rematch/core"
import { WORKSPACE_ID } from "api/api"
import {
  deleteExpandableExportApi,
  deleteExportApi,
  downloadExportApi,
  exportsCountApi,
  getWorkspaceExportsApi,
} from "api/endpoints/exports.api"
import { SortDirection } from "common/enums/SortDirection.enum"
import { ExportModel } from "common/models/ExportModel"
import { ExportRequestOptions } from "common/models/ExportRequestOptions"
import { PageModel } from "common/models/PageModel"
import { ExportDeletePayload } from "common/types/ExportDeletePayload.type"
import { ExportDownloadPayload } from "common/types/ExportDownloadPayload.type"

type State = Readonly<{
  downloads: ExportModel[]
  requestOptions: ExportRequestOptions
  totalItemsCount: number
}>

const model = {
  state: {
    downloads: [],
    totalItemsCount: 0,
    requestOptions: new ExportRequestOptions({
      pageModel: new PageModel({
        page: 1,
        pageSize: 10,
        sortField: "createdAt",
        sortDirection: SortDirection.Descending,
      }),
    }),
  } as State,
  reducers: {
    setDownloadsPaged: (state: State, payload: any): State => ({
      ...state,
      downloads: payload.items,
    }),
    setRequestOptions: (state: State, payload: ExportRequestOptions): State => {
      return {
        ...state,
        requestOptions: payload,
      }
    },
    setTotalItemsCount: (state: State, payload: any): State => {
      return {
        ...state,
        totalItemsCount: payload.totalItemsCount,
      }
    },
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async getWorkspaceExportsPaged(payload: ExportRequestOptions) {
      const downloads = await getWorkspaceExportsApi(WORKSPACE_ID, payload)

      if (downloads) {
        dispatch.exports.setDownloadsPaged({
          items: downloads.items,
        })
      }
    },
    async deleteExport(payload: ExportDeletePayload) {
      const { exportId, blobName, requestOptions } = payload
      const result = await deleteExportApi(WORKSPACE_ID, exportId, blobName)

      if (result) {
        dispatch.exports.setRequestOptions(requestOptions)
        this.getExportsCount()
      }
      return result
    },
    async deleteExpandableExport(payload: {exportId: string, requestOptions: ExportRequestOptions}) {
      const {exportId, requestOptions} = payload
      const result = await deleteExpandableExportApi(WORKSPACE_ID, exportId)

      if (result) {
        dispatch.exports.setRequestOptions(requestOptions)
        this.getExportsCount()
      }
      return result
    },
    async getExportsCount() {
      const { totalItemsCount } = await exportsCountApi(WORKSPACE_ID)

      if (totalItemsCount) {
        dispatch.exports.setTotalItemsCount({
          totalItemsCount,
        })
        return totalItemsCount
      }
      return false
    },
    async downloadExport(payload: ExportDownloadPayload) {
      const { exportId, blobName } = payload
      const download = await downloadExportApi(WORKSPACE_ID, exportId, blobName)

      if (download) {
        return download
      }
      return false
    },
  }),
}

export default model
