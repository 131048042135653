import "./catalog-filter-modal.scss"
import { useState } from "react"
import { Button } from "components/button/button"
import { GeneralFilters } from "./general-filters"
import { FlagFilters } from "./flag-filters"
import { HealthScoreFilters } from "./health-score-filters"
import { CustomFieldsFilters } from "./custom-fields-filters"
import { useModal } from "context/modal-context"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import { GENERAL_FILTER_FIELDS, SECONDARY_FILTER_FIELDS } from "./filter-constants"
import { SecondaryFilters } from "./secondary-filters"

export const CatalogFilterModal = ({
  initialFilters,
  updateFilters,
}: {
  initialFilters: ItemFilterModel
  updateFilters: (filters: ItemFilterModel, reset?: boolean) => void
}) => {
  const [innerFilterObject, setInnerFilterObject] = useState(
    initialFilters || new ItemFilterModel(),
  )
  const [selectedFilterCategory, setSelectedFilterCategory] =
    useState<FilterCategory>("Primary Filters")

  const { unSetModal } = useModal()

  let primaryFiltersCount = 0
  GENERAL_FILTER_FIELDS.forEach((filter) => {
    const { filterObjectAccessor } = filter
    const filterNumber = innerFilterObject?.[filterObjectAccessor]?.length ?? 0
    if (filterNumber > 0) primaryFiltersCount++
  })
  let secondaryFiltersCount = 0
  SECONDARY_FILTER_FIELDS.forEach((filter) => {
    const { filterObjectAccessor } = filter
    const isFilterApplied = innerFilterObject?.[filterObjectAccessor] !== undefined
    if (isFilterApplied) secondaryFiltersCount++
  })
  const flagFiltersCount = innerFilterObject.alertFilterModels?.length
  const healthScoreFiltersCount = innerFilterObject.scoreFilters?.length
  const customFiltersCount = innerFilterObject.customFields?.length

  const countObject = {
    "Primary Filters": primaryFiltersCount,
    "Secondary Filters": secondaryFiltersCount,
    Flags: flagFiltersCount,
    "Health Scores": healthScoreFiltersCount,
    "Custom Fields": customFiltersCount,
  }

  const menuItems = [
    "Primary Filters",
    "Secondary Filters",
    "Flags",
    "Health Scores",
    "Custom Fields",
  ] as const
  type FilterCategory = (typeof menuItems)[number]

  const filterDictionary: Record<FilterCategory, JSX.Element> = {
    "Primary Filters": (
      <GeneralFilters
        filterObject={innerFilterObject}
        setFilterObject={setInnerFilterObject}
      />
    ),
    "Secondary Filters": (
      <SecondaryFilters
        filterObject={innerFilterObject}
        setFilterObject={setInnerFilterObject}
      />
    ),
    Flags: (
      <FlagFilters
        filterObject={innerFilterObject}
        setFilterObject={setInnerFilterObject}
      />
    ),
    "Health Scores": (
      <HealthScoreFilters filters={innerFilterObject} setFilters={setInnerFilterObject} />
    ),
    "Custom Fields": (
      <CustomFieldsFilters
        filterObject={innerFilterObject}
        setFilterObject={setInnerFilterObject}
      />
    ),
  }

  const closeModal = () => {
    unSetModal()
  }

  const resetFilters = () => {
    setInnerFilterObject(new ItemFilterModel())
  }

  const applyFilters = () => {
    updateFilters(innerFilterObject)
    closeModal()
  }

  return (
    <div>
      <div className="text-title-3 font-semibold pb-2.5">Filter Your Data</div>
      <div className="text-body font-medium text-gray-500 pb-5">
        Easily narrow down the SKUS that you need.
      </div>
      <div className="filter-modal-content">
        <div className="filter-categories">
          {menuItems.map((item) => (
            <div
              key={item}
              className={`menu-item ${selectedFilterCategory === item && "selected"}`}
              onClick={() => setSelectedFilterCategory(item)}
            >
              {item} {(countObject[item] ?? 0) > 0 && <span>({countObject[item]})</span>}
            </div>
          ))}
        </div>
        <div className="category-content">{filterDictionary[selectedFilterCategory]}</div>
      </div>
      <div className="flex justify-between mt-[50px]">
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <div className="flex gap-3">
          <Button variant="outlined" onClick={resetFilters}>
            Reset Filters
          </Button>
          <Button variant="primary" onClick={applyFilters}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  )
}
