import { HealthScoreFilterModel } from "common/models/HealthScoreFilterModel"
import {
  SIGN,
  ValueSignOption,
} from "../catalog-filters/health-score-options/value-sign-option/value-sign-option"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { filter } from "lodash"
import { ScoreLevel } from "common/enums/ScoreLevel.enum"
import { ScoreFilterModel } from "common/models/ScoreFilterModel"
import { SectionScoreFilterModel } from "common/models/SectionScoreFilterModel"
import { RuleScoreFilterModel } from "common/models/RuleScoreFilterModel"

type HealthOption = {
  name: string
  value: number
  sign: SIGN
  checked: boolean
}

export const HealthScoreFilters = ({
  filters,
  setFilters,
}: {
  filters: ItemFilterModel
  setFilters: (filters: ItemFilterModel) => void
}) => {
  const filterModel = useSelector((state: RootState) => state.auditCatalog.filterModel)
  const healthScoreFilters = filterModel?.healthScoreFilters

  const getFilterIndex = (item: ScoreFilterModel): number => {
    if (healthScoreFilters) {
      return filters.scoreFilters
        ? filters.scoreFilters.findIndex(
            (filter) =>
              item.scoreLevel === filter.scoreLevel &&
              item.parentOperand === filter.parentOperand &&
              item.operand === filter.operand,
          )
        : -1
    }
    return -1
  }

  const setHealthScore = (item: HealthOption, level: ScoreLevel, parent: string = "") => {
    const healthScoreFilter = new ScoreFilterModel({
      scoreLevel: level,
      parentOperand: parent,
      operand: item.name,
      operator: item.sign,
      value: item.value,
    })

    const index = getFilterIndex(healthScoreFilter)
    const scoreFilters = filters.scoreFilters ? [...filters.scoreFilters] : []

    if (index === -1) {
      if (item.checked) {
        scoreFilters.push(healthScoreFilter)
      }
    } else {
      if (item.checked) {
        scoreFilters[index] = healthScoreFilter
      } else {
        scoreFilters.splice(index, 1)
      }
    }

    setFilters({
      ...filters,
      scoreFilters,
    })
  }

  const getValues = (
    filterModel:
      | HealthScoreFilterModel
      | SectionScoreFilterModel
      | RuleScoreFilterModel
      | undefined,
    parentName: string = "",
  ) => {
    if (filterModel) {
      const found = filters.scoreFilters?.find(
        (filter) =>
          filterModel.name === filter.operand &&
          filterModel.scoreLevel === filter.scoreLevel &&
          parentName === filter.parentOperand,
      )
      if (found) {
        return {
          checked: true,
          value: found?.value,
          sign: found?.operator as SIGN,
        }
      } else {
        return {
          checked: false,
          value: 100,
          sign: SIGN.LT,
        }
      }
    } else {
      return {
        checked: false,
        value: 100,
        sign: SIGN.LT,
      }
    }
  }

  let rowCount = 0

  return (
    <div className="health-score-filters-container">
      <div className={"grid w-full rounded bg-gray-50"}>
        <ValueSignOption
          name={healthScoreFilters?.name}
          {...getValues(healthScoreFilters, "")}
          labelSuffix=" score is"
          onChange={(value) => setHealthScore(value, ScoreLevel.TopLevel)}
          maxValue={100}
        />
      </div>
      {healthScoreFilters?.sectionScoreFilters.map((item, sectionIndex) => {
        rowCount += 1
        return (
          <div>
            <div
              className={`pl-[18px] ${rowCount % 2 === 0 ? "rounded bg-gray-50" : ""}`}
            >
              <ValueSignOption
                key={sectionIndex}
                name={item.name}
                {...getValues(item, healthScoreFilters?.name)}
                labelSuffix=" score is"
                onChange={(value) =>
                  setHealthScore(value, ScoreLevel.SectionLevel, healthScoreFilters?.name)
                }
                maxValue={100}
              />
            </div>
            {item.ruleScoreFilters.map((subItem, subIndex) => {
              rowCount += 1
              return (
                <div
                  className={`pl-9 ${rowCount % 2 === 0 ? "rounded bg-gray-50 " : ""}`}
                >
                  <ValueSignOption
                    key={subIndex}
                    name={subItem.name}
                    {...getValues(subItem, item.name)}
                    labelSuffix=" score is"
                    onChange={(value) =>
                      setHealthScore(value, ScoreLevel.RuleLevel, item?.name)
                    }
                    maxValue={100}
                  />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
