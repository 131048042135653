import { FC, memo, ReactNode } from "react"
import { TippyProps } from "@tippyjs/react"
import { TippyWrapper } from "components/tippyWrapper/tippyWrapper"
import "./icon.scss"

import {
  ExclamationTriangleIcon,
  BookmarkIcon,
  BellIcon,
  UserIcon,
  HomeIcon,
  PlusCircleIcon,
  ChartBarSquareIcon,
  SignalIcon,
  HeartIcon,
  CursorArrowRaysIcon,
  FlagIcon,
  FireIcon,
  KeyIcon,
  PhotoIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
  CheckIcon,
  XMarkIcon,
  Cog6ToothIcon,
  EyeIcon,
  TrashIcon,
  PencilIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  FunnelIcon,
  ViewColumnsIcon,
  MagnifyingGlassIcon,
  ArrowDownOnSquareStackIcon,
  TagIcon,
  ArrowDownTrayIcon,
  ShareIcon,
  StarIcon,
  DocumentDuplicateIcon,
  PaperAirplaneIcon,
  PlusIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  Bars3Icon,
  Bars2Icon,
  DocumentArrowDownIcon,
  InformationCircleIcon,
  ArrowSmallRightIcon,
  DocumentTextIcon,
  MinusIcon,
  ClipboardDocumentListIcon,
  EllipsisVerticalIcon,
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  CloudArrowUpIcon,
  WrenchScrewdriverIcon,
  CheckCircleIcon,
  CircleStackIcon,
  MegaphoneIcon,
  BanknotesIcon,
  CalendarDaysIcon,
  WrenchIcon,
  CheckBadgeIcon as CheckBadgeOutline,
  XCircleIcon as XCircleOutline,
} from "@heroicons/react/24/outline"

import {
  InformationCircleIcon as InformationCircleSolid,
  ChevronDownIcon as ChevronDownSolid,
  ChevronUpIcon as ChevronUpSolid,
  PlusCircleIcon as PlusCircleSolidIcon,
  ExclamationTriangleIcon as ExclamationSolidIcon,
  CheckIcon as CheckSolidIcon,
  XMarkIcon as XSolidIcon,
  ArrowSmallRightIcon as ArrowSmRightSolidIcon,
  CheckCircleIcon as CheckCircleSolidIcon,
  StarIcon as StarIconFull,
  XCircleIcon as XCircleSolidIcon,
} from "@heroicons/react/24/solid"

import { StarFull, StarEmpty } from "../../assets/icons/rating-stars"
import {
  Dashboard,
  ShareOfShelf,
  MyAudits,
  Reports,
  ContentInsights,
  CustomizeGrading,
  CreateAlerts,
  ViewAuditCatalog,
  ChevronRightLast,
  ChevronLeftFirst,
} from "../../assets/icons/navigation-icons/navigation-icons"
import {
  TriggerIcon,
  ExpandIcon,
  UploadFileIcon,
  RippleIcon,
  OverwriteIcon,
} from "../../assets/icons/app-icons/app-icons"

export const ICONS = {
  SAVE: BookmarkIcon,
  BELL: BellIcon,
  USER: UserIcon,
  HOME: HomeIcon,
  CHART_SQUARE_BAR: ChartBarSquareIcon,
  EXCLAMATION: ExclamationTriangleIcon,
  EXCLAMATION_SOLID: ExclamationSolidIcon,
  PLUS_CIRCLE: PlusCircleIcon,
  PLUS_CIRCLE_SOLID: PlusCircleSolidIcon,
  PLUS: PlusIcon,
  MINUS: MinusIcon,
  STATUS_ONLINE: SignalIcon,
  HEARTH: HeartIcon,
  CURSOR_CLICK: CursorArrowRaysIcon,
  FLAG: FlagIcon,
  EQUALS: Bars2Icon,
  CHEVRON_RIGHT: ChevronRightIcon,
  CHEVRON_LEFT: ChevronLeftIcon,
  CHEVRON_DOWN: ChevronDownIcon,
  CHEVRON_UP: ChevronUpIcon,
  CHEVRON_UP_SOLID: ChevronUpSolid,
  CHEVRON_DOWN_SOLID: ChevronDownSolid,
  VIEW: EyeIcon,
  PENCIL: PencilIcon,
  DELETE: TrashIcon,
  CANCEL: XMarkIcon,
  CHECK: CheckIcon,
  COPY: DocumentDuplicateIcon,
  CLIPBOARD_LIST: ClipboardDocumentListIcon,
  FIRE: FireIcon,
  PHOTOGRAPH: PhotoIcon,
  THUMBS_UP: HandThumbUpIcon,
  THUMBS_DOWN: HandThumbDownIcon,
  KEY: KeyIcon,
  COG: Cog6ToothIcon,
  ARROW_RIGHT: ArrowRightIcon,
  ARROW_LEFT: ArrowLeftIcon,
  FILTER: FunnelIcon,
  VIEW_BOARDS: ViewColumnsIcon,
  SEARCH: MagnifyingGlassIcon,
  SAVE_AS: ArrowDownOnSquareStackIcon,
  TAG: TagIcon,
  DOWNLOAD: ArrowDownTrayIcon,
  SHARE: ShareIcon,
  FEATURED: StarIcon,
  DOCUMENT_DUPLICATE: DocumentDuplicateIcon,
  DOCUMENT_TEXT: DocumentTextIcon,
  PAPER_AIRPLANE: PaperAirplaneIcon,
  STAR_FULL: StarFull,
  STAR_EMPTY: StarEmpty,
  FULL_STAR: StarIconFull,
  MENU: Bars3Icon,
  DOCUMENT_DOWNLOAD: DocumentArrowDownIcon,
  INFORMATION_CIRCLE_OUTLINED: InformationCircleIcon,
  INFORMATION_CIRCLE_SOLID: InformationCircleSolid,
  ARROW_SM_RIGHT: ArrowSmallRightIcon,
  ARROW_SM_RIGHT_SOLID: ArrowSmRightSolidIcon,
  DASHBOARD: Dashboard,
  SHARE_OF_SHELF: ShareOfShelf,
  MY_AUDITS: MyAudits,
  REPORTS: Reports,
  TOOLS: WrenchScrewdriverIcon,
  CONTENT_INSIGHTS: ContentInsights,
  CUSTOMIZE_GRADING: CustomizeGrading,
  CREATE_ALERTS: CreateAlerts,
  VIEW_AUDIT_CATALOG: ViewAuditCatalog,
  CHECK_SOLID_ICON: CheckSolidIcon,
  CANCEL_SOLID_ICON: XSolidIcon,
  CHECK_CIRCLE: CheckCircleIcon,
  CHECK_CIRCLE_SOLID: CheckCircleSolidIcon,
  TRIGGER: TriggerIcon,
  EXPAND: ExpandIcon,
  CHEVRON_RIGHT_LAST: ChevronRightLast,
  CHEVRON_LEFT_FIRST: ChevronLeftFirst,
  UPLOAD_FILE: UploadFileIcon,
  CURSOR_ARROW: RippleIcon,
  OVERWRITE: OverwriteIcon,
  KEBAB_MENU: EllipsisVerticalIcon,
  TRENDING_UP: ArrowTrendingUpIcon,
  TRENDING_DOWN: ArrowTrendingDownIcon,
  CLOUD_ARROW_UP: CloudArrowUpIcon,
  CIRCLE_STACK: CircleStackIcon,
  MEGAPHONE: MegaphoneIcon,
  BANKNOTES: BanknotesIcon,
  CALENDAR: CalendarDaysIcon,
  WRENCH: WrenchIcon,
  CHECK_BADGE_OUTLINE: CheckBadgeOutline,
  X_CIRLCE_SOLID: XCircleSolidIcon,
}

type Props = {
  icon: any
  className?: string
  size?: number
  tippyContent?: ReactNode
  tippyProps?: Partial<TippyProps>
}

export const Icon: FC<Props> = memo(
  ({ icon, className = "", size = 5, tippyContent, tippyProps }: Props) => {
    const IconComp = icon
    if (!icon) {
      return null
    }
    return (
      <TippyWrapper content={tippyContent} tippyProps={tippyProps}>
        <IconComp
          className={className}
          style={{
            width: size * 4,
            height: size * 4,
          }}
        />
      </TippyWrapper>
    )
  },
)
