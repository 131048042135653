import { RetailerModel } from "common/models/RetailerModel"
import { AuditType } from "common/enums/AuditType.enum"
import { API } from "../api"

export const getRetailersApi = (workspaceId: string): Promise<RetailerModel[]> =>
  API().get(`retailers?workspaceId=${workspaceId}`)

export const getRetailerByAuditTypeApi = (
  auditType: AuditType,
  workspaceId: string,
): Promise<RetailerModel[]> =>
  API().get(`retailers/${auditType}?workspaceId=${workspaceId}`)

export const getFrequentlyUsedByAuditTypeApi = (
  auditType: AuditType,
  workspaceId: string,
): Promise<RetailerModel[]> =>
  API().get(`retailers/${auditType}/frequently-used/${workspaceId}`)

export const getRetailerCategoriesApi = (
  retailerId: string,
  workspaceId: string,
): Promise<string[]> => API().get(`retailers/${retailerId}/categories/${workspaceId}`)

export const getRetailerBrandsApi = (
  retailerId: string,
  workspaceId: string,
): Promise<string[]> => API().get(`retailers/${retailerId}/brands/${workspaceId}`)

export const postRetailerApi = (body: {
  name: string
  description: string
  workspaceId: string
}) => API().post("retailers", body)

export const deleteRetailerApi = (workspaceId: string, retailerId: string) =>
  API().delete(`retailers/${retailerId}/workspaces/${workspaceId}`)

export const putRetailerApi = (body: any) => API().put("retailers", body)
