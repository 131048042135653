import { useEffect, useRef, useState } from "react"
import "./filterable-multiselect.scss"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { Icon, ICONS } from "components/icon/icon"
import { createPortal } from "react-dom"

export const FilterableMultiselect = ({
  options,
  selectedOptions,
  setSelectedOptions,
  isDisabled = false,
  width = 230,
  menuHeight = 214,
  controlHeight = 24,
  hasCommonAccessor = false,
}: {
  options: string[]
  selectedOptions: string[]
  setSelectedOptions: (optionList: string[]) => void
  isDisabled?: boolean
  width?: number
  menuHeight?: number
  controlHeight?: number
  hasCommonAccessor?: boolean
}) => {
  const [inputValue, setInputValue] = useState("")
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [sortedOptions, setSortedOptions] = useState(options.slice(0, 50))

  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const innerSelectedOptions = hasCommonAccessor
    ? selectedOptions.filter((option) => options.includes(option))
    : selectedOptions
  const isAnyOptionSelected = innerSelectedOptions.length > 0
  const inputPadding = innerSelectedOptions.length > 9 ? "pl-12" : "pl-10"

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      const moreOptions = options
        .slice(sortedOptions.length, sortedOptions.length + 50)
        .filter((option) => !selectedOptions.includes(option))
      if (moreOptions.length > 0) {
        setSortedOptions((prev) => [...prev, ...moreOptions])
      }
    }
  }

  const handleInputClick = () => {
    if (!isDisabled) {
      setIsMenuOpen(true)
      if (inputRef) inputRef.current?.focus()
    }
  }

  const handleOptionClick = (option: string) => {
    const isOptionSelected = selectedOptions.includes(option)
    if (isOptionSelected) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option))
    } else {
      setSelectedOptions([...selectedOptions, option])
    }
  }

  const clearSelection = () => {
    setSelectedOptions(
      selectedOptions.filter((option) => !innerSelectedOptions.includes(option)),
    )
  }

  const clearSearchField = () => {
    setInputValue("")
  }

  const dropdown = (
    <div
      ref={dropdownRef}
      onScroll={handleScroll}
      className="absolute z-20 mt-1 bg-white border rounded shadow overflow-y-auto py-1.5 pl-3 pr-1.5"
      style={{
        top: inputRef.current?.getBoundingClientRect().bottom ?? 0 + window.scrollY,
        right: window.innerWidth - (inputRef.current?.getBoundingClientRect().right ?? 0),
        width,
        maxHeight: menuHeight,
      }}
    >
      {sortedOptions.length > 0 ? (
        sortedOptions.map((option, index) => {
          return (
            <label
              key={`${option}-${index}`}
              className="flex items-center gap-3 hover:bg-gray-100 rounded"
            >
              <div className="w-[14px]">
                <Checkbox
                  disabled={isDisabled}
                  value={!!selectedOptions.find((el) => el === option)}
                  small
                  onChange={() => {
                    if (inputRef) inputRef.current?.focus()
                    handleOptionClick(option)
                  }}
                />
              </div>
              <div
                className="overflow-hidden whitespace-nowrap text-ellipsis text-caption-2 font-semibold"
                title={option}
              >
                {option}
              </div>
            </label>
          )
        })
      ) : (
        <div className="flex text-caption-2 text-gray-500 font-semibold justify-center py-1.5">
          <span>No Results</span>
        </div>
      )}
    </div>
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false)
        clearSearchField()
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const finalOptions =
      inputValue != ""
        ? options.filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase()),
          )
        : options

    setSortedOptions([
      ...innerSelectedOptions.filter((option) => finalOptions.includes(option)),
      ...finalOptions.slice(0, 50).filter((option) => !selectedOptions.includes(option)),
    ])
  }, [isMenuOpen, inputValue])

  return (
    <div className="relative">
      <input
        ref={inputRef}
        placeholder={isAnyOptionSelected ? "" : "Select"}
        style={{ width: width, height: controlHeight }}
        className={`w-full h-full p-2 border rounded border-gray-300 focus:border-blue-600 focus:outline-none focus:border-2 placeholder:text-gray-300 ${
          isDisabled ? "opacity-50" : ""
        } ${
          isAnyOptionSelected ? inputPadding : "focus:pl-[7px]"
        } text-caption-2 font-semibold w-[150px] cursor-default`}
        value={inputValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setInputValue(e.target.value)
        }
        onClick={handleInputClick}
      />
      {inputValue.length > 0 && (
        <div
          onClick={clearSearchField}
          className="absolute right-6 top-[5px] border-r border-gray-200 pr-1"
        >
          <Icon icon={ICONS.CANCEL} size={3.5} className="" />
        </div>
      )}
      <div onClick={handleInputClick}>
        <Icon
          icon={isMenuOpen ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
          size={3.5}
          className="absolute right-2 top-[5px] transition-transform duration-200"
        />
      </div>
      {isAnyOptionSelected && (
        <div className="absolute flex gap-1 items-center top-[3px] left-[3px] font-semibold text-caption-2 text-gray-900 border rounded border-gray-200 px-1 py-[1px]">
          <div>{innerSelectedOptions.length}</div>
          <div className="cursor-pointer" onClick={clearSelection}>
            <Icon icon={ICONS.CANCEL} size={3.5} className="text-gray-500" />
          </div>
        </div>
      )}
      {isMenuOpen && createPortal(dropdown, document.body)}
    </div>
  )
}
