import { AlertFilterModel } from "common/models/AlertFilterModel"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import {
  SIGN,
  ValueSignOption,
} from "../catalog-filters/health-score-options/value-sign-option/value-sign-option"
import { FLAG_FILTER_FIELDS, FlagFilterFieldsIdentifiers } from "./filter-constants"
import { AlertOption } from "../catalog-filters/alert-score-options/alert-score-options"

export const FlagFilters = ({
  filterObject,
  setFilterObject,
}: {
  filterObject: ItemFilterModel
  setFilterObject: React.Dispatch<React.SetStateAction<ItemFilterModel>>
}) => {
  const { alertFilterModels = [] } = filterObject

  const getValueAndSign = (identifier: FlagFilterFieldsIdentifiers) => {
    const alertFilter = alertFilterModels?.find(
      (filter) => filter.alertIdentifier === identifier,
    )

    if (alertFilter) {
      return {
        value: alertFilter.value,
        sign: alertFilter.operator as SIGN,
      }
    }

    return { value: 5, sign: SIGN.LT }
  }

  const setFilter = (item: AlertOption, identifier: FlagFilterFieldsIdentifiers) => {
    const alertScoreFilter = new AlertFilterModel({
      alertIdentifier: identifier,
      operator: item.sign,
      value: Number(item.value),
    })
    const newAlertFiltersModel = alertFilterModels ? [...alertFilterModels] : []

    const index = alertFilterModels?.findIndex(
      (alertFilterModel) => alertFilterModel.alertIdentifier === identifier,
    )

    if (index === -1) {
      if (item.checked) {
        newAlertFiltersModel.push(alertScoreFilter)
      }
    } else {
      if (item.checked) {
        newAlertFiltersModel[index] = alertScoreFilter
      } else {
        newAlertFiltersModel.splice(index, 1)
      }
    }

    setFilterObject({
      ...filterObject,
      alertFilterModels: newAlertFiltersModel,
    })
  }

  const isChecked = (identifier: FlagFilterFieldsIdentifiers) => {
    return !!alertFilterModels?.find(
      (alertFilter) => alertFilter.alertIdentifier === identifier,
    )
  }

  return (
    <div className="flag-filter-container">
      {FLAG_FILTER_FIELDS.map((field, index) => {
        const { label, identifier } = field
        return (
          <div
            key={`${label}-${index}`}
            className={`grid w-full px-1.5 ${
              index % 2 === 0 ? "rounded bg-gray-50" : ""
            }`}
          >
            <ValueSignOption
              name={label}
              checked={isChecked(identifier)}
              {...getValueAndSign(identifier)}
              onChange={(value) => {
                setFilter(value, identifier)
              }}
            />
          </div>
        )
      })}
    </div>
  )
}
