import { useNavigate } from "react-router-dom"
import { Card } from "components/card/card"
import { Button } from "components/button/button"
import { PageNotFoundIcon, NetworkErrorIcon } from "assets/icons/app-icons/app-icons"
import { useSelector } from "react-redux"
import { RootState } from "store"
import "./error.scss"
import { useMsal } from "@azure/msal-react"
import { logout } from "utils"
import { useEffect } from "react"

export const NetworkError = (): JSX.Element => {
  const navigate = useNavigate()
  const path = useSelector((state: RootState) => state.errorPath.path)

  useEffect(() => {
    const storedPath = sessionStorage.getItem("previousPath")
    if (storedPath) {
      sessionStorage.removeItem("previousPath")
      navigate(storedPath)
    }
  }, [navigate])

  return (
    <div className="page-not-found-wrapper">
      <Card className="card-container">
        <div className="icon-positioning">
          <PageNotFoundIcon />
        </div>
        <div className="content-wrapper">
          <div className="main-text">Page Not Found!</div>
          <div className="secondary-text">
            We are sorry , the page you’re looking for doesn’t work. Please try to reload
            it.
          </div>
          <div className="button-centering">
            <Button
              className="primary"
              onClick={() => {
                sessionStorage.setItem("previousPath", path)

                window.location.reload()
              }}
            >
              Reload Page
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}

export const WorkspaceError = (): JSX.Element => {
  const { instance } = useMsal()
  const navigate = useNavigate()

  return (
    <div className="network-error-wrapper">
      <Card className="card-container">
        <div className="icon-positioning">
          <NetworkErrorIcon />
        </div>
        <div className="content-wrapper">
          <div className="main-text">Oops! Something Went Wrong!</div>
          <div className="secondary-text">
            Sorry , we can’t get that information right now. Please try again.
          </div>
          <div className="button-centering">
            <Button className="primary" onClick={() => logout(instance, navigate)}>
              Go to Login Page
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}
