import { ItemFilterModel } from "common/models/ItemFilterModel"

import { SECONDARY_FILTER_FIELDS } from "./filter-constants"

import { FilterSelect } from "components/filter-select/filter-select"

export const SecondaryFilters = ({
  filterObject,
  setFilterObject,
}: {
  filterObject: ItemFilterModel
  setFilterObject: React.Dispatch<React.SetStateAction<ItemFilterModel>>
}) => {
  const {
    isPrime,
    hasPrimaryKeywords,
    hasSecondaryKeywords,
    has360Spins,
    hasEnhancedContent,
  } = filterObject

  const secondaryFiltersObject = {
    isPrime,
    hasPrimaryKeywords,
    hasSecondaryKeywords,
    has360Spins,
    hasEnhancedContent,
  }

  const selectOptions = [
    { label: "True", value: true },
    { label: "False", value: false },
  ]

  return (
    <div className="secondary-filters-container">
      {SECONDARY_FILTER_FIELDS.map((field, index) => {
        const { label, filterObjectAccessor } = field
        const isActive = secondaryFiltersObject[filterObjectAccessor] !== undefined

        return (
          <div
            key={`${label}-${index}`}
            className={`flex justify-between items-center px-3.5 py-[3px] ${
              index % 2 === 0 ? "rounded bg-gray-50" : ""
            }`}
          >
            <div
              className={`text-caption-1 ${
                isActive ? "text-gray-900 font-medium" : "text-gray-500"
              }`}
            >
              {label}
            </div>
            <div>
              <FilterSelect
                options={selectOptions}
                value={secondaryFiltersObject[filterObjectAccessor]}
                setSelectedOption={(option: any) => {
                  const newFilterObject = { ...filterObject }
                  const newValue = option ? option.value : undefined
                  newFilterObject[filterObjectAccessor] = newValue
                  setFilterObject(newFilterObject)
                }}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
