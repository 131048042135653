export type GeneralFiltersOptionsObject = {
  dataSource: string[]
  auditNames: string[]
  fulfillmentType: string[]
  brands: string[]
  mainCategory: string[]
  subCategory1: string[]
  subCategory2: string[]
  systemTags: string[]
  customTags: string[]
}

export type GeneralFiltersItemFilterModelKeys =
  | "dataSource"
  | "jobNames"
  | "fulfillmentType"
  | "brands"
  | "category"
  | "firstSubCategory"
  | "secondSubCategory"
  | "tags"

interface GeneralFilterField {
  label: string
  accessor: keyof GeneralFiltersOptionsObject
  filterObjectAccessor: GeneralFiltersItemFilterModelKeys
}

export const GENERAL_FILTER_FIELDS: GeneralFilterField[] = [
  {
    label: "Data Source",
    accessor: "dataSource",
    filterObjectAccessor: "dataSource",
  },
  {
    label: "Audit Names",
    accessor: "auditNames",
    filterObjectAccessor: "jobNames",
  },
  {
    label: "Fulfillment Type",
    accessor: "fulfillmentType",
    filterObjectAccessor: "fulfillmentType",
  },
  {
    label: "Brands",
    accessor: "brands",
    filterObjectAccessor: "brands",
  },
  {
    label: "Main Category",
    accessor: "mainCategory",
    filterObjectAccessor: "category",
  },
  {
    label: "Sub-Category 1",
    accessor: "subCategory1",
    filterObjectAccessor: "firstSubCategory",
  },
  {
    label: "Sub-Category 2",
    accessor: "subCategory2",
    filterObjectAccessor: "secondSubCategory",
  },
  {
    label: "System Tags",
    accessor: "systemTags",
    filterObjectAccessor: "tags",
  },
  {
    label: "Custom Tags",
    accessor: "customTags",
    filterObjectAccessor: "tags",
  },
]

interface FlagFilterField {
  label: string
  identifier: FlagFilterFieldsIdentifiers
}

export const FLAG_FILTER_FIELDS: FlagFilterField[] = [
  {
    label: "Total flagged items is",
    identifier: "flagsCount",
  },
  {
    label: "Warning flags is",
    identifier: "warningFlagsCount",
  },
  {
    label: "Critical flags is",
    identifier: "criticalFlagsCount",
  },
  {
    label: "Indicator flags is",
    identifier: "indicatorFlagsCount",
  },
]

export type FlagFilterFieldsIdentifiers =
  | "flagsCount"
  | "warningFlagsCount"
  | "criticalFlagsCount"
  | "indicatorFlagsCount"

export type SecondaryFiltersItemFilterModelKeys =
  | "isPrime"
  | "hasPrimaryKeywords"
  | "hasSecondaryKeywords"
  | "has360Spins"
  | "hasEnhancedContent"

interface SecondaryFiltersField {
  label: string
  filterObjectAccessor: SecondaryFiltersItemFilterModelKeys
}

export const SECONDARY_FILTER_FIELDS: SecondaryFiltersField[] = [
  {
    label: "Is Prime",
    filterObjectAccessor: "isPrime",
  },
  {
    label: "Has Primary Keywords",
    filterObjectAccessor: "hasPrimaryKeywords",
  },
  {
    label: "Has Secondary Keywords",
    filterObjectAccessor: "hasSecondaryKeywords",
  },
  {
    label: "Has 360 Spins",
    filterObjectAccessor: "has360Spins",
  },
  {
    label: "Has Enhanced Content",
    filterObjectAccessor: "hasEnhancedContent",
  },
]
